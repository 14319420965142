<template>

        <div>
            
                <div class="fixed-top">
                    <section class="header-match ">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="content">
                                    <div class="left">
                                        <a :href="basePath">
                                            <img class="logo" src="/images/rehab_logo.svg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                    <div class="progres-box"><div class="progres-box-inside"><div data-v-515de9e4="" id="progres" class="progresbar-5 "></div></div></div>
           

            <div class="center">
                <div class="md-layout md-alignment-top-center md-gutter">
                    <div class="choice-wrapper">
                        <h1 class="mobilehide">Introducing all new premium listings</h1>
                        <h1 class="desktophide">All new premium listings</h1>
                        <h2>Choose a plan that is right for your program</h2>

                        <div class="choices">
                            <div
                                    class="choice"
                                    @click="makeChoice('Premium')"
                            >
                                <h5>Premium</h5>
                                <template v-if="subscriptionCostsOverridesByFacility(pricingFacilityId) && subscriptionCostsOverridesByFacility(pricingFacilityId)[0]">
                                <h2><span v-show="!loading" >${{ subscriptionCostsOverridesByFacility(pricingFacilityId)[0].overrides  }}/mo</span></h2>
                                </template>
                                <template v-else-if="subscriptioncosts.overrides">
                                <h2><span v-show="!loading">${{ subscriptioncosts.overrides}}/mo</span></h2>
                                </template>
                                <template v-else>
                                <h2><span v-show="!loading">${{ subscriptioncosts.defaults}}/mo</span></h2>
                                </template>
                                <div class="grow">Get more new patient connections.</div>
                                <hr>
                                <div class="question mobilehide">Industry leading tools to get more new patient connections.</div>
                                <ul class="question">
                                    <li><md-icon>check</md-icon>Free matches</li>
                                    <li><md-icon>check</md-icon>Website URL</li>
                                    <li><md-icon>check</md-icon>Respond to patient reviews</li>
                                    <li><md-icon>check</md-icon>Update content</li>
                                    <li><md-icon>check</md-icon>Unlimited forms</li>
                                    <li><md-icon>check</md-icon>Replace third-party ads</li>

                                </ul>
                            </div>
                            <div
                                    class="choice last-step"
                                    @click="makeChoice('Basic')"
                            >
                                <h5>Basic</h5>
                                <h2>Free</h2>
                                <div class="grow">Update your listing.</div>
                                <hr>
                                <div class="question mobilehide">Customize your listing details, respond to reviews and more.</div>
                                <ul class="question">
                                    <li><md-icon>check</md-icon>Free matches</li>
                                    <li><md-icon>check</md-icon>Website URL</li>
                                    <li><md-icon>check</md-icon>Respond to patient reviews</li>
                                    <li><md-icon>check</md-icon>Update content</li>
                                    <li class="cross"><md-icon>clear</md-icon>Unlimited forms</li>
                                    <li class="cross"><md-icon>clear</md-icon>Replace third-party ads</li>

                                </ul>
                            </div>
                        </div>


                    </div>


                </div>
            </div>


        </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    export default {
        name: "PremiumOrBasic",
        data() {
            return {
                loading: false,
                basePath: null,
            }
        },

        computed: {
            ...mapGetters([
                'hubspotContact',
                'facilitySubscriptionCost',
                'totalSubscriptionCost',
                'subscriptioncosts',
                'facilities',
                'goV2Facility',
                'subscriptionCostsOverridesByFacility'
            ]),

            pricingFacilityId() {
                if(this.goV2Facility){
                    return this.goV2Facility
                }else {
                    return this.facilities[0].attributes.facilityId
                }
            }

        },

        async mounted() {
            this.loading = true;
            await this.fetchFacilityOperationType();
            await this.fetchSubscriptionCosts();
            this.loading = false;

            // SEND BASIC TO HUBSPOT
            try {
                await this.fetchHubspotContactByEmail();
            }catch (e) {
                console.error('ERROR FETCHING HUBSPOT CONTACT', e);
            }

            try {
                await this.setHubspotPlanIntent('Basic');
            }catch(e) {
                console.error('ERROR UPDATING PLAN INTENT', e);
            }


            await this.createStatusLog({
                type: 'ViewedPremiumVsBasicPage',
                action: 'viewed'
            })
            
            if(window.location.origin === "https://connect-staging.rehab.com"){
                this.basePath = `https://staging.rehab.com/`
            }else if(window.location.origin === "https://connect-dev.rehab.com"){
                this.basePath = `https://dev.rehab.com/`
            }else if(window.location.origin === "https://connect-demo.rehab.com"){
                this.basePath = `https://demo.rehab.com/`
            }else if(window.location.origin === "https://connect.rehab.com"){
                this.basePath = `https://www.rehab.com/`
            } else{
                this.basePath = `http://local.rehab.com/`
            }


        },

        methods: {
            ...mapActions([
                'fetchHubspotContactByEmail',
                'setHubspotPlanIntent',
                'createStatusLog',
                'fetchFacilityOperationType',
                'fetchSubscriptionCosts'
            ]),
            async makeChoice(choice) {
                // SEND CHOICE TO HUBSPOT
                await this.setHubspotPlanIntent(choice);

                // REDIRECT TO PREMIUM DASHBOARD.
                this.$router.push({name: 'dashboard.premium', query: {plan: choice} })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .center{
        background-color: #fff!important;
        min-height: 100vh!important;
        padding-top: 40px;
    }
    
    .fixed-top{
        position: relative;
        .header-match, .header-match .content {
            position: relative;
            margin-left: auto;
            margin-right: auto;
        } 
        .header-match {
            height: 66px;
            background-color: #fbfbfb;
            border-bottom: 1px solid #eee;
            font-family: Lato,sans-serif;
            padding-left: 15px;
            padding-right: 15px;
        }
    
        .header-match .content {
            width: 1140px;
            padding-top: 18px;
        }
    }
   .desktophide{
       display: none;
   }
    .page-container, .md-app, .md-app-container, .md-app-scroller, .md-layout-column, .md-flex, .md-theme-default, .md-scrollbar, .md-content, .md-drawer, .md-theme-default, .md-tabs-content {
        background-color: #ffffff !important;
    }

    .md-toolbar {
        background-color: #FAFAFA !important;
    }
    .logo{
        width: 170px;
        .rehab-logo {
            height: 32px;
        }
    }
    .md-app{
        padding-bottom: 10px!important;
    }

    .md-primary{
        border: 1px solid #eee
    }

    h1 {
        margin-top: 10px;
        margin-bottom: 0;
        letter-spacing: -0.2px;
        font-size: 36px;
        color: #000000;
        font-weight: 300;
        line-height: 1.2;
        font-family: "Lato", sans-serif;
        -webkit-font-smoothing: auto;
    }

    h2 {
        font-family: "Lato", sans-serif;
        font-size: 24px;
        color: #000000;
        font-weight: 200;
        margin-bottom: 0;
        line-height: 1.2;
        margin-top: 10px;
    }

    .choice-wrapper {
        .choices {
            width: 100%;
            /*padding: 2%;*/
            margin-top: 50px;

            .last-step {
                margin-left: 26px;
            }

            .choice {
                font-family: "Lato", sans-serif;
                padding: 30px;
                display: inline-table;
                width: 320px;
                max-height: 432px;
                padding-top: 20px;
                border: 1px solid #ccc;
                border-radius: 8px;
                padding-bottom: 0px;
                min-height: 404px;

                h5 {
                    color: #000;
                    font-size: 24px;
                    margin-top: 0;
                    margin-bottom: .5rem;
                    font-weight: 500;
                    line-height: 1.2;
                    display: block;
                     -webkit-font-smoothing: auto;
                }

                h2 {
                    font-size: 32px;
                    color: #000;
                    margin-top: 0;
                    margin-bottom: .5rem;
                    font-weight: 500;
                    line-height: 1.2;
                     -webkit-font-smoothing: auto;
                     min-height: 38px;
                     height: 38px;
                }

                hr {
                    border: 0;
                    border-top: 1px solid #ccc;
                    margin-bottom: 14px;
                    margin-top: 1rem;
                }

                .question {
                    display: block;
                    font: 400 15px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
                     font-weight: 400;
                    color: #6f6f6f;
                    letter-spacing: .2px;
                    padding-bottom: 8px;
                    line-height: 24px;
                    padding-right: 15px;
                    min-height: 52px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin-top: 0;
                    margin-bottom: 1rem;

                    li {
                        font: 400 15px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
                        font-weight: 400;
                        color: #6f6f6f;
                        letter-spacing: .2px;
                        line-height: 28px;
                        .md-icon {
                            color: #3AD612;
                            margin-right: 10px;
                        }
                    }
                    li.cross{
                        .md-icon {
                            color: #D21C27
                        }
                    }
                }
            }

            .choice:hover {
                border: 1px solid #0078d3;
                cursor: pointer;
            }
        }

      

        @media(max-width: 760px) {
            .choices {
                .choice {
                    display: block;
                    float: left;
                    margin-left: 0;
                    margin-top: 0px;
                    padding: 20px;
                    width: 320px;
                    position: relative;
                    padding-top: 14px;
                    margin-bottom: 20px;
                    h2{
                        font-size: 28px;
                        margin-bottom: 0;
                        min-height: 33px;
                        height: unset;
                    }
                    h5{
                        font-size: 17px;
                    }
                }
                .last-step {
                    margin-left: 0;
                }
            }

        }

        @media(max-width: 576px) {
            padding-right: 10px;
            h1 {
                font-size: 24px;   
                margin-top: 0;
            }
         
            h2 {
                font-size: 16px;
                margin-top: 8px
            }
            .choices {
                width: 100%;
                .choice {
                    width: 100%;
                }
            }

        }
    }

     .progres-box {
        position: absolute;
        margin: 0 auto;
        text-align: center;
        height: 8px;
        background-color: #e7e7e7;
        border-radius: 8px;
        z-index: 10;
        overflow: hidden;
        top: 29px;
        width: 445px;
        margin-left: -223px;
        margin-right: auto;
        left: 50%;
        .progres-box-inside {
            position: relative;
            margin: 0 auto;
            top: 0;
            .progresbar-5[data-v-515de9e4] {
                position: absolute;
                height: 8px;
                width: 90%;
                background-color: #0078d3 !important;
                z-index: 11;
            }
        }
    }

     @media(max-width: 992px) {
        .fixed-top{
            display: none;
        }
         .center{
            background-color: #fff!important;
            min-height: 100vh!important;
            padding-top: 20px;
            padding-left: 0px;
            padding-right: 15px;
        }
        .progres-box {
            position: absolute;
            margin: 0;
            text-align: center;
            height: 8px;
            width: 100%;
            background-color: #e7e7e7;
            z-index: 10;
            overflow: hidden;
            margin-top: 0px;
            max-width: unset;
            padding: 0px;
            left: 0;
            top: 0;
            border-radius: 0px;

            .progres-box-inside{
                position: relative;
                margin: 0 auto;
                top: 0;
                .progresbar-5 {
                    position: absolute;
                    height: 8px;
                    width: 90%;
                    background-color: #0078d3!important;
                    z-index: 11;
                }
            }
        }
    }



        @media(max-width: 760px) {

            .center{
                background-color: #fff!important;
                min-height: 99vh!important;
                padding-top: 20px;
                padding-left: 24px;
                
            }

            .mobilehide{
                display: none!important;
            }
            .desktophide{
                display: block;
            }

            .choice-wrapper .choices{
                margin-top: 26px;
               
                h5{
                    margin-bottom: 0px!important;
                }
                 hr{
                     margin-bottom: 10px!important;
                     margin-top: 10px!important;
                 }
                 li{
                     line-height: 24px!important;
                 }
                 .question{
                     padding-bottom: 0px!important;
                     margin-bottom: 0px!important;
                 }

                .choice {
                    min-height: revert;
                }
            }
            .logo{
                display: none;
            }
            .md-primary{
                border: none;
            }
            .md-toolbar{
                background-color: unset!important;
                height: 8px;
                margin: 0;
                padding: 0;
                display: block;
                min-height:26px;
            }
            .md-app-content{
                padding-top: 0px;
            }
            .progres-box {
                position: relative;
                margin: 0;
                text-align: center;
                height: 8px;
                width: 100%;
                background-color: #e7e7e7;
                z-index: 10;
                overflow: hidden;
                margin-top: 0px;
                max-width: unset;
                padding: 0px;
                left: 0;
                border-radius: 0px;

                .progres-box-inside{
                    position: relative;
                    margin: 0 auto;
                    top: 0;
                    .progresbar-5 {
                        position: absolute;
                        height: 8px;
                        width: 90%;
                        background-color: #0078d3!important;
                        z-index: 11;
                    }
                }
            }
        
        }


</style>